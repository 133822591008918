import React from "react"
import styles from './pricing.module.css'
import MainLayout from "../layouts/MainLayout"
import SignupButton from "../components/SignupButton"
import SEO from "../components/seo"

const PricingPage = () => {
  return (
    <MainLayout>
      <SEO title="Crowdlever pricing - Recent order notifications for your online store" />
      <div className={styles.section}>
        <center><h1>Simple pricing, designed to help you grow</h1></center>

        <div className={styles.planList}>

          <div className={styles.planBlock}>
            <div className={styles.name}>Solo</div>
            <div className={styles.details}>
              <div className={styles.price}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>9.99</span>
              </div>
              <div className={styles.featureList}>
                <p className={styles.batteries}>Plan includes:</p>
                <ul>
                    <li>30k notifications/month</li>
                    <li>Basic email support</li>
                    <li>Basic customisation</li>
                    <li>Conversion tracking and analytics</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.planBlock}>
            <div className={styles.name}>Startup</div>
            <div className={styles.details}>
              <div className={styles.price}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>29.99</span>
              </div>
              <div className={styles.featureList}>
                <p className={styles.batteries}>Plan includes:</p>
                <ul>
                    <li>300k notifications/month</li>
                    <li>Email support</li>
                    <li>Conversion tracking and analytics</li>
                    <li>Notifications are fully customisable</li>
                </ul> 
              </div>
            </div>
          </div>

          <div className={styles.planBlock}>
            <div className={styles.name}>Business</div>
            <div className={styles.details}>
              <div className={styles.price}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>149.99</span>
              </div>
              <div className={styles.featureList}>
                <p className={styles.batteries}>Plan includes:</p>
                <ul>
                    <li>3M notifications/month</li>
                    <li>Priority email support</li>
                    <li>API access</li>
                    <li>Conversion tracking and analytics</li>
                </ul>
              </div>
            </div>
          </div>

        </div>

        <div className={styles.signup}>
          <SignupButton href={`${process.env.GATSBY_FRONTEND_APP_URL}/signup`} />
          <div className={styles.beta}>Free 30 day trial, no credit card required</div>
        </div>

      </div>
    </MainLayout>
  )
}

export default PricingPage