import React from 'react'
import styles from './SignupButton.module.css'

const SignupButton = ({ subtitle='', onClick, href }) => {

  const handleClick = (evt) => {
    evt.preventDefault()
    if (onClick !== undefined) {
      onClick()
      return
    }
    if (href !== undefined) {
      window.location.href = href
      return
    }
  }

  return (
    <div className={styles.root}>
      <button onClick={handleClick} href="#" className={styles.btn}>Get started for free</button>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  )
}

export default SignupButton